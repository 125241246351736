import { Button, GlobalContext, Input, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useContext, useEffect, useState } from 'react';
import { EditMarketInfo, GetMarketInfo, getCountryByCode } from '../../services/bookings/types';
import editMarket from '../../services/markets/editMarkets';
import getMarkets from '../../services/markets/getMarkets';
import { PageHeading } from '../../components';
import { handleAction } from '../HistoryLog/historyLogService';
import { ActionStatus } from '../HistoryLog/types';

const FuelPrices = () => {
  const { alert } = useContext(GlobalContext);
  async function fetchMarketInfos() {
    try {
      const fetchedMarketInfos: GetMarketInfo[] = await getMarkets();
      setMarkets(fetchedMarketInfos);
    } catch (error) {
      alert.show('Failed to fetch markets:', 'error');
    }
  }
  useEffect(() => {
    fetchMarketInfos();
  }, []);
  const [markets, setMarkets] = useState<GetMarketInfo[]>([]);

  const submitFuelChange = () => {
    markets.map(market => {
      if (market.isChanged) {
        const editModel: EditMarketInfo = {
          fuel: market.fuelPerLiter,
          buffer: market.buffer,
          currency: market.currency,
          maxCostLimitPerBooking: market.maxCostLimitPerBooking,
          vat: market.vat,
          serviceFees: market.serviceFees,
        };
        editMarket(editModel, market.market)
          .then(() => {
            fetchMarketInfos();
            alert.show('Successfully updated market', 'success');
            handleAction(ActionStatus.MODIFYING_FUEL_FEE, [{ market: market.market }]);
          })
          .catch(() => {
            fetchMarketInfos();
            alert.show('Unable to update market', 'error');
          });
      }
    });
  };

  const renderTableHeader = () => {
    return (
      <Thead>
        <Tr>
          <Th>
            <span className="font-medium text-sm text-neutral-400">Country Name</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400">Currency</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400">Fuel Cost/Litre</span>
          </Th>
        </Tr>
      </Thead>
    );
  };

  const renderTableBody = () => {
    return (
      <Tbody>
        {markets?.map(item => (
          <Tr key={item.market}>
            <Td extraClasses="border-bottom px-4 py-2 font-bold">{getCountryByCode(item.market)}</Td>
            <Td extraClasses="border-bottom px-4 py-2 font-medium">{item.currency}</Td>
            <Td extraClasses="border-bottom px-4 py-2" align="center">
              <div className="flex items-center">
                <Input
                  type="number"
                  value={item.fuelPerLiter?.toFixed(2) ? item.fuelPerLiter?.toFixed(2) : ''}
                  onChange={e => handleFeeChange(item.market, +e.currentTarget.value)}
                />
              </div>
            </Td>
          </Tr>
        ))}
      </Tbody>
    );
  };

  const handleFeeChange = (market: string, newFee: number) => {
    const updatedData = markets.map(item => {
      if (item.market === market) {
        item.isChanged = true;
        return { ...item, fuelPerLiter: newFee };
      }
      return item;
    });
    setMarkets(updatedData);
  };

  return (
    <div className="min-h-screen">
      <PageHeading title="Fuel Prices" description="A list of country with fuel fees list."></PageHeading>

      <Table extraClasses="min-w-max">
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
      <Button
        onClick={submitFuelChange}
        variant="primary"
        extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
        Submit
      </Button>
    </div>
  );
};

export default FuelPrices;
