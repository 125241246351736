import resolveFeatureFlag from '../config/featureFlags';

type RouteDefinition = {
  name: string;
  path: string;
  isAvailable: boolean;
};

type RouteDefinitions = {
  [key: string]: RouteDefinition;
};

const paths = {
  gdpr: 'gdpr',
  root: '/',
  users: 'users',
  home: '/dashboard',
  applications: 'applications',
  availabilities: 'availabilities',
  bookings: 'bookings',
  pricing: 'pricing',
  export: 'export',
  servicefees: 'servicefees',
  historylog: 'historylog',
  fuelprices: 'fuelprices',
  bookingrequesterror: 'bookingrequesterror',
  sendbird: {
    reports: 'reports',
    profanity: 'profanity',
  },
};

const routeDefinitions: RouteDefinitions = {
  login: {
    name: 'Login',
    path: paths.root,
    isAvailable: true,
  },
  sendbirdprofanity: {
    name: 'Sendbird Profanity',
    path: paths.sendbird.profanity,
    isAvailable: true,
  },
  sendbirdreports: {
    name: 'Sendbird Reports',
    path: paths.sendbird.reports,
    isAvailable: true,
  },
  home: {
    name: 'Home',
    path: paths.home,
    isAvailable: true,
  },
  gdpr: {
    name: 'GDPR',
    path: paths.gdpr,
    isAvailable: resolveFeatureFlag('GDPR'),
  },
  users: {
    name: 'Users',
    path: paths.users,
    isAvailable: resolveFeatureFlag('USERS'),
  },
  applications: {
    name: 'Applications',
    path: paths.applications,
    isAvailable: resolveFeatureFlag('APPLICATIONS'),
  },
  availabilities: {
    name: 'Availabilities',
    path: paths.availabilities,
    isAvailable: resolveFeatureFlag('AVAILABILITIES'),
  },
  bookings: {
    name: 'Bookings',
    path: paths.bookings,
    isAvailable: resolveFeatureFlag('BOOKINGS'),
  },
  pricing: {
    name: 'Pricing',
    path: paths.pricing,
    isAvailable: resolveFeatureFlag('PRICING'),
  },
  export: {
    name: 'Export',
    path: paths.export,
    isAvailable: resolveFeatureFlag('EXPORT'),
  },
  servicefees: {
    name: 'Service Fees',
    path: paths.servicefees,
    isAvailable: resolveFeatureFlag('SERVICE_FEES'),
  },
  historylog: {
    name: 'History Log',
    path: paths.historylog,
    isAvailable: resolveFeatureFlag('HISTORY_LOG'),
  },
  fuelprices: {
    name: 'Fuel Fees',
    path: paths.fuelprices,
    isAvailable: resolveFeatureFlag('FUEL_PRICES'),
  },
  bookingrequesterror: {
    name: 'Booking Request',
    path: paths.bookingrequesterror,
    isAvailable: resolveFeatureFlag('REQUEST_ERRORS'),
  },
};

export default routeDefinitions;
