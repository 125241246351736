/**
 * Feature flags
 *
 * When adding/removing feature flags make sure to update them in here and document them in `ENV.md` under the feature flags section
 *
 * @remarks
 * If a feature should be disabled it must be explicitly set to `false` in `.env`. If a feature flag is registered in the `flagNames` array but not set in `.env` it will default to `true`.
 * This is to prevent accidentally disabling features by omission and needing to update code once a feature becomes available but instead only update environment configs.
 */

const flagNames = [
  'GLOBAL',
  'APPLICATIONS',
  'BOOKINGS',
  'GDPR',
  'PRICING',
  'AVAILABILITIES',
  'USERS',
  'EXPORT',
  'SERVICE_FEES',
  'HISTORY_LOG',
  'REQUEST_ERRORS',
  'FUEL_PRICES',
] as const;

export type FeatureFlag = typeof flagNames[number];

type FeatureFlags = {
  [key in FeatureFlag]: boolean;
};

function resolveValue(value?: string) {
  if (value === 'true' || value === undefined) {
    return true;
  } else {
    return false;
  }
}

const flags = flagNames.reduce((acc, flagName) => {
  acc[flagName] = resolveValue(process.env[`REACT_APP_FEATURE_${flagName}`]);
  return acc;
}, {} as FeatureFlags);

function resolveFeatureFlag(flag: FeatureFlag): boolean {
  return flags[flag];
}

export default resolveFeatureFlag;
